import { COMPONENT_CONFIGURATION_LABELS } from '../../../../../../constants';
import ConfigSection from '../../ConfigSection';
import type { ComposerElement } from '../../../../../../../../types';

interface Props {
    modifierOptions: { [key: string]: boolean };
    modifierValues: Partial<ComposerElement>;
    updateEditable: (isEditable: boolean) => void;
    updateMultiSelect: (isMultiSelect: boolean) => void;
    updateSearchable: (isSearchable: boolean) => void;
    updateSearchableDisplayColumns: (hasSearchableDisplayColumns: boolean) => void;
    updateAutoUpload: (autoUpload: boolean) => void;
}

const ModifiersSection = ({
    modifierOptions,
    modifierValues,
    updateEditable,
    updateMultiSelect,
    updateSearchable,
    updateSearchableDisplayColumns,
    updateAutoUpload,
}: Props) => {
    if (!(modifierOptions || modifierValues)) {
        return null;
    }

    const { hasEditable, hasMultiselect, hasSearchable, hasAutoUpload } = modifierOptions ?? {};

    const { isEditable, isMultiSelect, isSearchable, isSearchableDisplayColumns, attributes } =
        modifierValues;

    const isAutoUpload =
        typeof attributes?.['$autoUpload'] === 'string' &&
        attributes?.['$autoUpload'].toLowerCase() === 'true';

    const hideModifersSection =
        modifierOptions && Object.values(modifierOptions).every((val) => !val);

    if (hideModifersSection) {
        return null;
    }

    return (
        <ConfigSection contentLayout="flex-row" dataTestId="modifiers-section">
            <>
                {hasEditable && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateEditable(!isEditable);
                                }}
                                checked={isEditable}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['EDITABLE']}
                        </label>
                    </div>
                )}
            </>

            <>
                {hasMultiselect && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateMultiSelect(!isMultiSelect);
                                }}
                                checked={isMultiSelect}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['MULTISELECT']}
                        </label>
                    </div>
                )}
            </>

            <>
                {hasSearchable && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateSearchable(!isSearchable);
                                }}
                                checked={isSearchable}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['SEARCHABLE']}
                        </label>
                    </div>
                )}
            </>

            <>
                {hasSearchable && isSearchable && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateSearchableDisplayColumns(!isSearchableDisplayColumns);
                                }}
                                checked={isSearchableDisplayColumns}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['SEARCHABLEDISPLAYCOLUMNS']}
                        </label>
                    </div>
                )}
            </>

            <>
                {hasAutoUpload && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateAutoUpload(!isAutoUpload);
                                }}
                                checked={isAutoUpload}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['AUTO_UPLOAD']}
                        </label>
                    </div>
                )}
            </>
        </ConfigSection>
    );
};

export default ModifiersSection;
